
* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}





ul {
 list-style: none;
}

a {
 text-decoration: none;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    html {
        font-family: "Inter", sans-serif;
      }
  }
